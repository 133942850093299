
import etherLogo from "../Contents/images/ether-logo.png";
import { getWindowDimensions, getStorage, setStorage, removeStored } from './general'
export const initialState = {
    SITE_NAME: "Decentrade Finance Inc.",
    API_URL: "https://service-route-001.online/v1",
    ZERO_ADDRESS: "0x0000000000000000000000000000000000000000",
    wallet: getStorage("session-storage", "wallet") != null ? getStorage("session-storage", "wallet") : null,
    networks: getStorage("local-storage", "networks") != null ? JSON.parse(getStorage("local-storage", "networks")) : null,
    activeNetwork: getStorage("local-storage", "active-network") != null ? JSON.parse(getStorage("local-storage", "active-network")) : null,
    bearer: getStorage("cookies", "bearer") != null ? getStorage("cookies", "bearer") : null,
    refreshToken: getStorage("cookies", "refresh-token") != null ? getStorage("cookies", "refresh-token") : null,
    notification: null,
    showConnectWallet: false,
    showDisconnectWallet: false,
    showSettings: false,
    slippage: getStorage("cookies", "slippage") && getStorage("cookies", "slippage") !== "auto" ? parseFloat(getStorage("cookies", "slippage")) : getStorage("cookies", "slippage") === "auto" ? "auto" : 5,
    deadline: getStorage("cookies", "deadline") ? parseFloat(getStorage("cookies", "deadline")) : 10,
    tokens: getStorage("local-storage", "tokens") ? JSON.parse(getStorage("local-storage", "tokens")) : null,
    fetching: false,
    poolsViewType: getStorage("cookies", "pools-view-type") || "block",
    showPoolForm: false,
    poolData: null
}

const reducer = (state, action)=>{
    switch (action.type) {
        case "WALLET":
            if (action.wallet == null) {
                removeStored("session-storage", "wallet");
                return {
                    ...state,
                    wallet: null
                }
            } else {
                setStorage("session-storage", "wallet", action.wallet, 7);
                return {
                    ...state,
                    wallet: action.wallet
                }
            }
        case "NETWORKS":
            setStorage("local-storage", "networks", JSON.stringify(action.networks));
            return {
                ...state,
                networks: action.networks
            }

        case "ACTIVE-NETWORK":
            setStorage("local-storage", "active-network", JSON.stringify(action.activeNetwork));
            return {
                ...state,
                activeNetwork: action.activeNetwork
            }

        case "NOTIFICATION":
            return {
                ...state,
                notification: action.notification
            }
        case "WALLET_CONNECT_VISIBILITY":
            return {
                ...state,
                showConnectWallet: action.showConnectWallet
            }
        case "WALLET_DISCONNECT_VISIBILITY":
            return {
                ...state,
                showDisconnectWallet: action.showDisconnectWallet
            }
        case "SETTINGS":
            return {
                ...state,
                showSettings: action.showSettings
            }
        case "SLIPPAGE":
            setStorage("cookies", "slippage", action.slippage);
            return {
                ...state,
                slippage: action.slippage
            }
        case "DEADLINE":
            setStorage("cookies", "deadline", action.deadline);
            return {
                ...state,
                deadline: action.deadline
            }
        case "TOKENS":
            if (action.tokens) {
                setStorage("local-storage", "tokens", JSON.stringify(action.tokens), 7);
            }
            return {
                ...state,
                tokens: action.tokens
            }
        case "FETCHING":
            return {
                ...state,
                fetching: action.fetching
            }
        case "POOLS_VIEW_TYPE":
            const _view = action?.view || "block";
            setStorage("cookies", "pools-view-type", _view);
            return {
                ...state,
                poolsViewType: _view
            }
        case "POOL_FORM":
            return {
                ...state,
                poolData: action.data || null,
                showPoolForm: action.show || false
            }
        default:
            return state;
    }
}

export default reducer;