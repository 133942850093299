import React, { useEffect, useState } from "react";
import { MdOutlineClose, MdOutlineSearch } from "react-icons/md";
import placeHolderCoin from "../Contents/images/favicon.png";
import { useStateValue } from "../Context/StateProvider";
import Button from "./Button";
import axios from "axios";
import loaderGif from "../Contents/images/loader.gif";


const TokensSelector = ({ tokens, base, quote, selectMethod, closeSelector }) => {
    const [{API_URL, activeNetwork, tokens: globalTokens}, payload] = useStateValue();
    const [searchQuery, setSearchQuery] = useState("");
    const [topTokens, setTopTokens] = useState([]);
    const [displayTokens, setDisplayTokens] = useState([]);
    const [loadingToken, setLoadingToken] = useState(false);

    const importToken = (token) => {
        delete token.import;
        payload({
            type: "TOKENS",
            tokens: {...globalTokens, [activeNetwork.symbol.toLowerCase()]: [...tokens, token]}
        });
        selectMethod(token);
    }

    useEffect(() => {
        setTopTokens(tokens.slice(0, 6))
        setDisplayTokens(tokens.slice(6, tokens.length));
    }, [tokens])
    useEffect(() => {
        if (searchQuery != "") {
            if (searchQuery.length === 42) {
                // fetch/import token from server
                const _importToken = async () => {
                    setDisplayTokens([]);
                    setLoadingToken(true);
                    try {
                        const {data: response} = await axios.get(`${API_URL}/tokens/meta/${searchQuery}/${activeNetwork.chain_id}`);
                        const _token = response.data;
                        _token.import = true;
                        setDisplayTokens([_token]);
                    } catch(err) {} finally {
                        setLoadingToken(false);
                    }
                }
                const _tokens = tokens.filter((token) => token.address.toLowerCase() == searchQuery.toLowerCase());
                if (_tokens.length > 0) {
                    setDisplayTokens(_tokens);
                } else {
                    _importToken();
                }
            } else {
                // search within available tokens
                const newDisplayTokens = tokens.filter((token) => 
                    token.name.toLowerCase().includes(searchQuery.toLowerCase()) || 
                    token.symbol.toLowerCase().includes(searchQuery.toLowerCase()) || 
                    token.address.toLowerCase().includes(searchQuery.toLowerCase())
                );
                setDisplayTokens(newDisplayTokens);
            }
        } else {
            setDisplayTokens(tokens.slice(6, tokens.length));
        }
    }, [searchQuery])
    return (
        <div className="backdrop">
            <div className="modal box-450">
                <div className="modal-header flex-box flex-align-center-box flex-justify-apart">
                    <div>
                        <div className="medium-text bold-text secondary-color-text">Select a token</div>
                        <div className="small-text grey-color-text">Select a token from our default list <br />or search for a token by symbol or address.</div>
                    </div>
                    <MdOutlineClose onClick={() => closeSelector()} />
                </div>
                <div className="modal-body large-z-pd-box animate-grow flex-box flex-gap-large flex-direction-column">
                    <div className="search-box">
                        <input 
                            className="full-width" 
                            type="text" 
                            name="search" 
                            placeholder="Search by token or address"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)} />
                        <label htmlFor="search" className="flex-box flex-align-justify-center-box">
                            <MdOutlineSearch />
                        </label>
                    </div>
                    <div className="full-width main-tokens grid-box grid-box-3x flex-gap-medium">
                        {
                            topTokens && topTokens.length >= 6 ? topTokens.map((token, index) => (
                                token.thumbnail && token.symbol ? (
                                    <div
                                        key={index}
                                        className={`token-btn cursor-pointer centered-box-y small-x-pd-box medium-y-pd-box flex-box flex-align-justify-center-box flex-gap-large ${[base, quote].includes(token.symbol.toLowerCase()) ? "selected" : ""}`}
                                        onClick={[base, quote].includes(token.symbol.toLowerCase()) ? null : () => selectMethod(token)}>
                                        <div className="icon">
                                            <img className="medium-icon" src={token.thumbnail || placeHolderCoin} alt="" />
                                        </div>
                                        <div className="symbol small-text bold-text">{token.symbol}</div>
                                    </div>
                                ) : null
                            )) : null
                        }
                    </div>
                    <div className="tokens-list full-width">
                        {
                            displayTokens.length > 0 ? displayTokens.map((token, index) => (
                                <div
                                    key={index}
                                    className={`token cursor-pointer full-width flex-box flex-align-center-box flex-justify-apart ${[base, quote].includes(token.symbol.toLowerCase()) ? "selected" : ""}`}
                                    onClick={token.import ? null : () => selectMethod(token)}>
                                    <div className="token-id flex-box flex-align-center-box flex-justify-apart flex-gap-small">
                                        <div className="icon">
                                            <img className="large-icon" src={token.thumbnail || placeHolderCoin} alt="" />
                                        </div>
                                        <div className="symbol small-text bold-text">{token.symbol}</div>
                                    </div>
                                    <div className="token-balance bold-text grey-color-text fade-color-background">
                                    {token.import ? 
                                        <Button 
                                            innerHTML={"Import"} 
                                            clickMethod={() => importToken(token)}
                                            className={"primary-color-background"} 
                                        /> : 
                                        parseFloat(token?.balance || "0.0000").toFixed(5)}
                                    </div>
                                </div>
                            )) : (
                                <div className="full-width flex-box flex-align-justify-center-box tx-align-center large-x-mg-box">
                                {loadingToken ? <img src={loaderGif} alt="loading..." height={20} /> : <strong>No item/tokens to select.</strong>}
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TokensSelector;